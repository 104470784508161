<template>
  <div class="user-list">
    <portal to="breadcrumb">
      <div
        class="users-filter d-flex align-items-center justify-content-sm-between"
      >
        <div class="user-list-breadcrumb">
          <bread-crumb
            :activeModule="$route.meta.moduleLabel"
            :activePage="$route.meta.label"
            :activePageUrl="$route.path"
          />
        </div>
        <div class="filter position-relative" v-clickoutside="closeFilter">
          <b-button variant="brand-secondary" @click="showFilter = !showFilter"
            ><i class="icon-filter"></i> Filter</b-button
          >
          <div
            class="filter-menu p-3 bg-white shadow-sm radius-25 position-absolute"
            v-if="showFilter"
            :class="filterStyle"
          >
            <b-form-group
              label-for="rolesOptions"
              label-class="form-label"
              label="Sites:"
              v-slot="{ ariaDescribedby }"
            >
              <b-dropdown
                id="site-selection-form"
                text="Stores"
                ref="siteFiltering"
                no-caret
                lazy
                variant="brand-dropdown"
                block
                menu-class="w-100"
                :aria-describedby="ariaDescribedby"
                @show="toggleSiteDropdownFilter('open')"
                @hide="toggleSiteDropdownFilter('close')"
              >
                <b-dropdown-form @submit.prevent>
                  <div class="form-group">
                    <b-form-input
                      type="search"
                      class="nosubmit"
                      placeholder="Search"
                      v-model="searchSiteText"
                    />
                  </div>
                  <b-form-checkbox-group
                    class="scrollable"
                    id="sites"
                    v-model="storeFilterSelection"
                    :options="siteOptions"
                    name="sites"
                  ></b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-form-group>
            <hr />
            <b-form-group
              label-for="rolesOptions"
              label-class="form-label"
              label="User Roles:"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                id="rolesOptions"
                :aria-describedby="ariaDescribedby"
                v-model="roleFilterSelection"
                :options="rolesOptions"
                name="rolesOptions"
              ></b-form-checkbox-group>
            </b-form-group>
            <hr />
            <b-form-group
              label-for="statusOptions"
              label-class="form-label"
              label="Status:"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                id="statusOptions"
                :aria-describedby="ariaDescribedby"
                v-model="statusFilterSelection"
                :options="statusOptions"
                name="statusOptions"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </portal>
    <b-card class="mt-3 admin-grid">
      <div class="row d-md-flex align-items-center mb-0 mb-md-3">
        <div
          class="col-xl-4 col-md-6 col-sm-12 pl-xxl-0 pr-md-0 mb-2 mb-md-2 mb-xl-0"
        >
          <input
            type="search"
            v-model="userSearch"
            placeholder="Search"
            class="nosubmit w-100"
          />
        </div>
        <div
          class="col-xl-4 col-md-6 col-sm-12 pl-xxl-0 pr-md-0 mb-2 mb-md-2 mb-xl-0"
        >
          <b-dropdown
            id="dropdown-form"
            text="Give site access"
            ref="siteAccessDropdown"
            block
            menu-class="w-100"
            no-caret
            lazy
            variant="brand-dropdown"
            :disabled="userIds.length == 0"
          >
            <b-dropdown-form form-class="pr-3 pl-3" @submit.stop.prevent>
              <div class="form-group">
                <b-form-input
                  type="search"
                  class="nosubmit w-100"
                  placeholder="Search"
                  v-model="searchSiteText"
                />
              </div>
              <b-form-checkbox-group
                id="sites"
                v-model="selectedSites"
                :options="siteOptions"
                class="scrollable"
                name="sites"
              ></b-form-checkbox-group>
            </b-dropdown-form>
            <b-dropdown-item class="dropdown-footer">
              <b-button
                variant="brand-secondary"
                size="sm"
                @click="handleSiteAccess"
                :disabled="selectedSites.length == 0"
                >Apply</b-button
              >
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          class="col-xl-2 col-md-6 col-sm-12 pl-xxl-0 pr-md-0 mb-2 mb-md-0 mb-xl-0"
        >
          <b-dropdown
            id="user-status"
            text="Change status"
            ref="statusDropdown"
            block
            no-caret
            variant="brand-dropdown"
            menu-class="w-100 scrollable"
            :disabled="userIds.length == 0"
          >
            <b-dropdown-item
              v-for="(option, index) in statusOptions"
              :key="index"
              :active="selectedStatus == option.value"
              @click="setSelectedStatus(option)"
            >
              {{ option.text }}</b-dropdown-item
            >
            <b-dropdown-item class="dropdown-footer">
              <b-button
                variant="brand-secondary"
                size="sm"
                @click="handleChangeStatus"
                >Apply</b-button
              >
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="col-xl-2 col-md-6 col-sm-12 pl-xxl-0 mb-2 mb-md-0">
          <b-dropdown
            id="role-dropdown"
            text="Change role"
            ref="roleDropdown"
            block
            no-caret
            variant="brand-dropdown"
            menu-class="w-100 scrollable"
            :disabled="userIds.length == 0"
          >
            <b-dropdown-item
              v-for="(option, index) in rolesOptions"
              :key="index"
              :active="selectedRole == option.value"
              @click="setSelectedRole(option)"
            >
              {{ option.text }}</b-dropdown-item
            >
            <b-dropdown-item class="dropdown-footer">
              <b-button
                variant="brand-secondary"
                size="sm"
                @click="handleChangeRole"
                >Apply</b-button
              >
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <b-table
        hover
        responsive
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        tbody-tr-class="text-nowrap"
        ref="usersTable"
        show-empty
        :empty-text="$t('general.no-data-msg')"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :filter-debounce="300"
        :filter-function="filterTable"
        v-model="displayedRows"
      >
        <template #head(checkbox)>
          <b-form-checkbox
            v-model="allSelected"
            @change="toggleAll"
            class="align-items-start"
          >
          </b-form-checkbox>
        </template>
        <template #cell(checkbox)="data">
          <b-form-checkbox
            class="d-block"
            :id="'checkbox-' + data.item.userId"
            :value="data.item.userId"
            v-model="userIds"
          />
        </template>

        <template #cell(role)="data">
          <div @click="editUser(data.item)" class="editable-value">
            {{ showUserRole(data.item.userTypeId) }}
          </div>
        </template>

        <template #cell(status)="data">
          <div @click="editUser(data.item)" role="button">
            <span
              class="tag-status"
              :class="{
                inactive: data.item.status == 'InActive',
                active: data.item.status == 'Active',
              }"
              role="button"
              >{{ data.item.status }}</span
            >
          </div>
        </template>

        <template #cell(email)="data">
          <div
            @click="editUser(data.item)"
            class="editable-value"
            v-b-tooltip.hover="data.item.emailId"
          >
            {{ data.item.emailId | truncate(25, "....") }}
          </div>
        </template>

        <template #cell(stores)="data">
          <div
            @click="editUser(data.item)"
            class="editable-value"
            v-b-tooltip.hover="data.item.stores"
          >
            {{ data.item.stores | truncate(40, "....") }}
          </div>
        </template>

        <template #cell()="data">
          <div @click="editUser(data.item)" class="editable-value">
            {{ data.value }}
          </div>
        </template>
      </b-table>
      <table-pagination
        class="mt-3"
        :fromLimit="fromLimit | addLeadingZero"
        :toLimit="toLimit | addLeadingZero"
        :totalRecords="totalRecords | addLeadingZero"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @paginate="paginate"
        recordsTitle="users"
      />
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import config from "../../../../../config";
import { includes, forEach, map, ceil, uniq, upperFirst } from "lodash";
import TablePagination from "../../../../components/TablePagination.vue";
import BreadCrumb from "../../../../components/BreadCrumb.vue";

const { tableHeaders, userManager, statusOptions } = config;

const { modalSetting } = userManager;

export default {
  components: { TablePagination, BreadCrumb },
  name: "UsersIndex",
  data() {
    return {
      showFilter: false,
      isStoreFilterVisible: false,
      currentPage: 1,
      totalRecords: 0,
      totalPages: 0,
      perPage: 20,
      siteOptions: [],
      fields: tableHeaders["user-list"],
      allSelected: false,
      indeterminate: false,
      isStatusItemClicked: false,
      isRoleItemClicked: false,
      userIds: [],
      displayedRows: [],
      selectedSites: [],
      statusOptions: statusOptions,
      rolesOptions: userManager.userType,
      selectedStatus: 0,
      selectedRole: 1,

      userSearch: "",

      emailSearchText: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      searchSiteText: "",
      storeFilterSelection: [],
      roleFilterSelection: [],
      statusFilterSelection: [],
      filterOn: [],
      filter: {
        userSearch: "",

        emailId: "",
        firstName: "",
        lastName: "",
        stores: [],
        userTypeId: [],
        status: [],
      },
    };
  },
  computed: {
    ...mapGetters("userManager", ["gridList", "tblLoader"]),
    ...mapGetters("siteManager", ["storeList"]),
    userdata() {
      let data = this.gridList.map((item) => {
        let storeNames = item.stores != null ? item.stores.split(",") : [];
        return { ...item, storeNames: storeNames };
      });
      return data;
    },
    items() {
      return this.userdata;
    },
    fromLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.fromlimit;
    },
    toLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.tolimit;
    },
  },
  watch: {
    userSearch(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("userSearch");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("userSearch");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["userSearch"] = newValue;
    },
    emailSearchText(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("emailId");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("emailId");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["emailId"] = newValue;
    },
    firstNameSearchText(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("firstName");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("firstName");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["firstName"] = newValue;
    },
    lastNameSearchText(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("lastName");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("lastName");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["lastName"] = newValue;
    },
    storeFilterSelection(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("stores");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("stores");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["stores"] = newValue.length
        ? this.siteOptions
            .filter((option) => newValue.includes(option.value))
            .map((a) => a.text)
        : [];
    },
    roleFilterSelection(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("userTypeId");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("userTypeId");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["userTypeId"] = newValue;
    },
    statusFilterSelection(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("status");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("status");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["status"] = newValue.length
        ? this.statusOptions
            .filter((option) => newValue.includes(option.value))
            .map((a) => a.text)
        : [];
    },
    searchSiteText(newValue) {
      if (this.storeList.length > 0) {
        let newOptions = [];
        forEach(this.storeList, (site) => {
          if (
            site.storeName.toLowerCase().includes(newValue.toLowerCase()) ||
            newValue == ""
          ) {
            let obj = { text: site.storeName, value: site.storeId };
            newOptions.push(obj);
          }
        });
        this.siteOptions = newOptions;
      }
    },
    async gridList() {
      await this.resetPageFilters(1);
      let options = [];
      forEach(this.storeList, (site) => {
        let obj = { text: site.storeName, value: site.storeId };
        options.push(obj);
      });
      this.siteOptions = options;
    },
    async currentPage(newValue) {
      await this.resetPageFilters(newValue);
    },
    userIds(newValue) {
      if (
        this.displayedRows.length &&
        this.displayedRows.length == newValue.length
      ) {
        this.allSelected = true;
        this.indeterminate = false;
      } else {
        this.allSelected = false;
        this.indeterminate = true;
      }

      let selectedSites = [];
      forEach(this.displayedRows, (row) => {
        if (includes(newValue, row.userId)) {
          let userSitesAccess = JSON.parse(row.sitesAccess);
          if (userSitesAccess) {
            let sites = map(userSitesAccess, "SiteId");
            selectedSites = [...selectedSites, ...sites];
          }
        }
      });

      this.selectedSites = uniq(selectedSites);
    },
  },
  methods: {
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    ...mapActions("userManager", [
      "getAll",
      "updateUserTypes",
      "updateStatus",
      "userStoreMapping",
    ]),
    ...mapMutations("userManager", ["setItem"]),
    ...mapActions("siteManager", ["getStoreList"]),
    showUserRole(usertypeId) {
      let userType = userManager.userType.find(
        (usertype) => usertype.value == usertypeId
      );
      return userType.text;
    },
    toggleAll(checked) {
      this.indeterminate = false;
      let selectedUsers = [];
      let selectedSites = [];
      if (checked) {
        forEach(this.displayedRows, (row) => {
          selectedUsers.push(row.userId);
          let userSitesAccess = JSON.parse(row.sitesAccess);
          if (userSitesAccess) {
            let sites = map(userSitesAccess, "SiteId");
            selectedSites = [...selectedSites, ...sites];
          }
        });
      }
      this.userIds = selectedUsers;
      this.selectedSites = uniq(selectedSites);
    },
    setSelectedStatus(option) {
      this.isStatusItemClicked = true;
      this.selectedStatus = option.value;
    },
    setSelectedRole(option) {
      this.isRoleItemClicked = true;
      this.selectedRole = option.value;
    },
    async handleSiteAccess() {
      try {
        let userStoreAccess = [];
        forEach(this.userIds, (user) => {
          forEach(this.selectedSites, (site) => {
            let obj = { userId: user, storeId: site };
            userStoreAccess.push(obj);
          });
        });

        this.generalSetItem({ resource: "isLoading", payload: true });
        let data = {
          loginId: this.loggedUserId,
          roleId: 1,
          userStoreList: userStoreAccess,
        };
        await this.userStoreMapping(data);
        await this.getAll({ userId: this.loggedUserId });
        this.generalSetItem({ resource: "isLoading", payload: false });
      } catch {
        this.generalSetItem({ resource: "isLoading", payload: false });
      }
    },
    async handleChangeStatus() {
      let modalTitle = "";
      let modalDescription = "";

      if (this.selectedStatus == 0) {
        modalTitle = modalSetting.inActiveUser.title;
        modalDescription = modalSetting.inActiveUser.description;
      } else {
        modalTitle = modalSetting.activateUser.title;
        modalDescription = modalSetting.activateUser.description;
      }

      const h = this.$createElement;

      const titleVNode = h("h5", {
        class: ["modal-title font-weight-bold"],
        domProps: { innerHTML: modalTitle },
      });

      this.$bvModal
        .msgBoxConfirm(modalDescription, {
          title: [titleVNode],
          size: "sm",
          buttonSize: "md",
          okVariant: "brand-primary w-50",
          cancelVariant: "brand-secondary border-0 w-50",
          okTitle: "Save",
          cancelTitle: "Cancel",
          headerClass:
            "justify-content-center p-3 border-bottom-0 font-weight-bolder",
          footerClass: "p-3 border-top-0 flex-nowrap",
          hideHeaderClose: true,
          centered: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        })
        .then(async (value) => {
          if (value) {
            this.generalSetItem({ resource: "isLoading", payload: true });
            let data = {
              loginId: this.loggedUserId,
              userIds: this.userIds.join(),
              status: this.selectedStatus,
            };
            await this.updateStatus(data);
            await this.getAll({ userId: this.loggedUserId });
            this.generalSetItem({ resource: "isLoading", payload: false });
          }
        })
        .catch(() => {
          // An error occurred
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    async handleChangeRole() {
      let modalTitle = modalSetting.changeRole.title;
      let modalDescription = modalSetting.changeRole.description;

      const h = this.$createElement;

      const titleVNode = h("h5", {
        class: ["modal-title font-weight-bold"],
        domProps: { innerHTML: modalTitle },
      });

      this.$bvModal
        .msgBoxConfirm(modalDescription, {
          title: [titleVNode],
          size: "sm",
          buttonSize: "md",
          modalClass: "deleteTag",
          okVariant: "brand-primary w-50",
          cancelVariant: "brand-secondary border-0 w-50",
          okTitle: "Save",
          cancelTitle: "Cancel",
          headerClass:
            "justify-content-center p-3 border-bottom-0 font-weight-bolder",
          footerClass: "p-3 border-top-0 flex-nowrap",
          hideHeaderClose: true,
          centered: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        })
        .then(async (value) => {
          if (value) {
            this.generalSetItem({ resource: "isLoading", payload: true });
            let data = {
              loginId: this.loginId,
              userIds: this.userIds.join(),
              usertypeId: this.selectedRole,
            };
            await this.updateUserTypes(data);
            await this.getAll({ userId: this.loggedUserId });
            this.generalSetItem({ resource: "isLoading", payload: false });
          }
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    async editUser(item) {
      this.$router.push({ name: "edit-users", params: { id: item.userId } });
    },
    async resetPageFilters(currentPage) {
      this.currentPage = currentPage;
      this.userIds = [];
      this.siteOptions = [];
      this.selectedSites = [];
      this.allSelected = this.indeterminate = false;
      await this.getStoreList({ userId: this.loggedUserId });
    },
    paginate(eventType) {
      if (eventType == "next") {
        this.currentPage = this.currentPage + 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    },
    filterTable(row, filter) {
      let filterCondition = {};
      if (this.filterOn.length) {
        this.filterOn.forEach((item) => {
          if (filter[item].length) {
            filterCondition[item] = true;
            if (
              item == "emailId" ||
              item == "firstName" ||
              item == "lastName"
            ) {
              if (!row[item].toLowerCase().includes(filter[item])) {
                filterCondition[item] = false;
              }
            } else if (item == "userTypeId") {
              if (!filter[item].includes(row[item])) {
                filterCondition[item] = false;
              }
            } else if (item == "status") {
              if (!filter[item].includes(upperFirst(row[item].toLowerCase()))) {
                filterCondition[item] = false;
              }
            } else if (item == "stores") {
              let existingSites = {};
              row.storeNames.forEach((site) => {
                existingSites[site] = false;
                if (filter[item].includes(site)) existingSites[site] = true;
              });
              const areTrue = Object.values(existingSites).some(
                (value) => value === true
              );
              filterCondition[item] = areTrue;
            } else if (item == "userSearch") {
              let emailMatched = row["emailId"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());
              let firstNameMatched = row["firstName"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());
              let lastNameMatched = row["lastName"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());

              filterCondition[item] =
                emailMatched || firstNameMatched || lastNameMatched;
            }
          }
        });
      }

      const areTrue = Object.values(filterCondition).every(
        (value) => value === true
      );
      return areTrue;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
      this.totalRecords = filteredItems.length;
      this.totalPages = ceil(filteredItems.length / this.perPage);
    },
    resetGridFilters() {
      this.userSearch = "";
      this.emailSearchText = "";
      this.firstNameSearchText = "";
      this.lastNameSearchText = "";
      this.searchSiteText = "";
      this.storeFilterSelection = [];
      this.roleFilterSelection = [];
      this.statusFilterSelection = [];
      this.filterOn = [];
      this.filter = {
        userSearch: "",
        emailId: "",
        firstName: "",
        lastName: "",
        stores: [],
        userTypeId: [],
        status: [],
      };
    },
    closeFilter() {
      this.showFilter = false;
      this.toggleSiteDropdownFilter("close");
    },
  },
  created() {
    this.setItem({ resource: "searchQuery", payload: "" });
    this.generalSetItem({ resource: "isLoading", payload: true });
    this.resetGridFilters();
    this.getAll({ userId: this.loggedUserId })
      .then(() => {
        this.generalSetItem({ resource: "isLoading", payload: false });
      })
      .catch(() => {
        this.generalSetItem({ resource: "isLoading", payload: false });
      });
  },
  mounted: function () {
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      if (bvEvent.componentId === "site-selection-form") {
        this.isStoreFilterVisible = true;
      }
    });
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (bvEvent.componentId === "user-status") {
        if (this.isStatusItemClicked) {
          bvEvent.preventDefault();
          this.isStatusItemClicked = false;
        }
      }
      if (bvEvent.componentId === "role-dropdown") {
        if (this.isRoleItemClicked) {
          bvEvent.preventDefault();
          this.isRoleItemClicked = false;
        }
      }
      if (bvEvent.componentId === "site-selection-form") {
        this.isStoreFilterVisible = false;
      }
      if (this.isStoreFilterVisible) {
        bvEvent.preventDefault();
      }
    });
  },
  destroyed() {
    this.setItem({ resource: "searchQuery", payload: "" });
    this.resetGridFilters();
  },
};
</script>
